const flag = "production";

const env = {
  production: {
    API_URL: process.env.REACT_APP_API_URL,
    FRONTEND_URL: process.env.REACT_APP_FRONTEND_URL,
    FRONTEND_URL: "",
  },
  staging: {
    API_URL: process.env.REACT_APP_API_URL,
    FRONTEND_URL: process.env.REACT_APP_FRONTEND_URL,
  },
};

export default {
  ...env[flag],
  flag: flag,
};
